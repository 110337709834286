import { BREAKPOINTS, mediaQuery } from '@theme/responsive'
import styled from 'styled-components'

import { ISubmitButtonProps } from '@components/forms/SubmitButton/models'

export const StyledSubmitButtonContainer = styled.button<ISubmitButtonProps>`
  display: flex;
  padding: 10px 15px;
  align-items: center;
  background: ${({ theme }) => theme.colors.grayWii};
  border: 2px solid ${({ theme }) => theme.colors.redJEM};
  color: ${({ theme }) => theme.colors.redStrawberryShortcake};
  border-radius: 10px;
  > span + div {
    margin-left: 15px;
  }
  > div + span {
    margin-left: 15px;
  }
  :disabled {
    opacity: 0.8;
    color: ${({ theme }) => theme.colors.redHungryHippo};
  }
  ${mediaQuery(
    BREAKPOINTS.MEDIUM,
    `
       padding: 15px 20px;
    `
  )}
`
