import { SlicesState, useStore } from '@state/useStore'

import { generateRandomString } from '@lib/utils'

import { IToast, IUseToastReturn, ToastTypes } from '@models/toast'

export const MAX_TOASTS = 5
export const DEFAULT_TOAST_CLOSE = 3000

const toastsSelector = (state: SlicesState) => ({
  toasts: state.toasts,
  setToasts: state.setToasts,
  getLatestToast: state.getLatestToast,
})

const useToast = (): IUseToastReturn => {
  const { toasts, setToasts, getLatestToast } = useStore(toastsSelector)

  const deleteToast = (id: string, onClose?: () => void) => {
    const currentToasts = useStore.getState().toasts

    const toastToDeleteIndex = currentToasts.findIndex((toast) => toast.id === id) ?? 0

    currentToasts.splice(toastToDeleteIndex, 1)
    setToasts([...currentToasts])

    onClose?.()
  }

  const getLatestAppError = () => getLatestToast()

  const constructToast = ({
    id: customId,
    onClose,
    headerText,
    message = getLatestAppError(),
    noManualClose = false,
    noAutoClose = false,
    autoCloseTime = DEFAULT_TOAST_CLOSE,
    type = ToastTypes.NEUTRAL,
  }: IToast): IToast => {
    const id = customId || generateRandomString(8)

    return {
      id,
      message,
      headerText,
      type,
      noManualClose,
      noAutoClose,
      autoCloseTime,
      onClose,
    }
  }

  const hasSameMessage = (newToast: IToast) =>
    useStore
      .getState()
      .toasts.find((toast) => toast.message === newToast.message || toast.id === newToast.id)

  const removeExtraToast = (allToasts: IToast[]) => {
    return allToasts.slice(0, -1)
  }

  const addToast = (toast: IToast) => {
    if (hasSameMessage(toast)) return

    const currentToasts = useStore.getState().toasts

    const oldToasts =
      currentToasts.length === MAX_TOASTS ? removeExtraToast(currentToasts) : currentToasts
    const newToast = constructToast(toast)
    setToasts([newToast, ...oldToasts])
  }

  const clearToasts = () => setToasts([])

  return { toasts, addToast, deleteToast, clearToasts, getLatestAppError }
}

export default useToast
