import DownArrowDisabled from '@public/icons/down-arrow-disabled.svg'
import DownArrow from '@public/icons/down-arrow.svg'
import React, {
  MouseEventHandler,
  RefObject,
  useState,
  SetStateAction,
  Fragment,
  useEffect,
  useRef,
} from 'react'
import { Dropdown } from 'react-bootstrap'

import {
  StyledDropDownContent,
  StyledDropdownFilterInput,
  StyledDropDownToggle,
  StyledDropMenu,
} from '@components/forms/DropDown/styles'
import { SvgLoader } from '@components/shared/SvgLoader'

import { CustomToggleProps, DropDownItem, IDropDownProps } from './models'

const CustomToggle = React.forwardRef(
  (
    {
      children,
      onClick,
      rightIcon,
      isOpen,
      disabled,
      hasDropDownArrow = true,
      noContent = true,
    }: CustomToggleProps,
    ref
  ) => {
    return (
      <StyledDropDownToggle
        disabled={disabled}
        ref={ref as RefObject<HTMLDivElement>}
        onClick={(e) => {
          e.preventDefault()
          if (disabled) return
          onClick(e as unknown as MouseEventHandler<HTMLDivElement>)
        }}
        isOpen={isOpen}
        noContent={noContent}
      >
        {rightIcon}
        <StyledDropDownContent>
          <span>{children}</span>
          {hasDropDownArrow && <SvgLoader {...(disabled ? DownArrowDisabled : DownArrow)} />}
        </StyledDropDownContent>
      </StyledDropDownToggle>
    )
  }
)

const findMatchingOptionForFilterInput = (items: DropDownItem[], value: string): string => {
  return items.find((item) => item.value === value)?.text ?? ''
}

export const DropDown: React.FC<IDropDownProps> = ({
  onChange,
  rightIcon,
  items,
  defaultValue,
  hasDropDownArrow,
  dropDownBodyContent,
  allowEmpty,
  placeholder,
  hasFilterItems,
  disabled,
}) => {
  const searchInputFilterRef = useRef<HTMLInputElement>(null)

  const [value, setValue] = useState<string>('')
  const [filterInputValue, setFilterInputValue] = useState<string>('')
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const handleOnChange = (eventKey: string | null) => {
    setValue(eventKey as unknown as SetStateAction<string>)

    if (hasFilterItems)
      setFilterInputValue(findMatchingOptionForFilterInput(items, eventKey as string))

    onChange?.(eventKey!)
  }

  useEffect(() => {
    const newValue = defaultValue !== undefined ? defaultValue : allowEmpty ? '' : items[0].value

    setValue(newValue)
    if (hasFilterItems) setFilterInputValue(findMatchingOptionForFilterInput(items, newValue))
  }, [defaultValue])

  const currentValue = items.find((item) => item.value.toString() === value.toString())?.text ?? ''

  const renderDropdownBody = () => {
    if (dropDownBodyContent) {
      return dropDownBodyContent
    } else if (currentValue) {
      return currentValue
    } else {
      return placeholder
    }
  }

  const renderFilterItemsInput = () => {
    return (
      <StyledDropdownFilterInput
        disabled={disabled}
        placeholder={placeholder}
        ref={searchInputFilterRef}
        onChange={(e) => setFilterInputValue(e.target.value)}
        value={filterInputValue}
      />
    )
  }

  const dropItems =
    hasFilterItems && filterInputValue
      ? items.filter((item) =>
          item.text.toLocaleLowerCase().includes(filterInputValue.toLocaleLowerCase())
        )
      : items

  return (
    <Dropdown
      onSelect={handleOnChange}
      data-testid="dropdown"
      onToggle={(newIsOpen: boolean) => {
        if (disabled) return
        if (hasFilterItems && newIsOpen) searchInputFilterRef?.current?.focus()
        setIsOpen(newIsOpen)
      }}
    >
      <Dropdown.Toggle
        disabled={disabled}
        as={CustomToggle}
        rightIcon={rightIcon}
        isOpen={isOpen}
        hasDropDownArrow={hasDropDownArrow}
        noContent={value === ''}
      >
        {hasFilterItems ? renderFilterItemsInput() : renderDropdownBody()}
      </Dropdown.Toggle>

      {!!dropItems.length && (
        <StyledDropMenu>
          {dropItems.map((dropdownItem) => {
            return (
              <Fragment key={dropdownItem.value}>
                {dropdownItem.hasDivider && <Dropdown.Divider />}
                <Dropdown.Item eventKey={dropdownItem.value} active={dropdownItem.value === value}>
                  {dropdownItem.leftIcon}
                  <span>{dropdownItem.text}</span>
                  {dropdownItem.rghtIcon}
                </Dropdown.Item>
              </Fragment>
            )
          })}
        </StyledDropMenu>
      )}
    </Dropdown>
  )
}
