import LeftArrowIcon from '@public/icons/left-arrow.svg'
import Link from 'next/link'
import React from 'react'

import { StyledBaseContainer } from '@components/layout/PageLayout/styles'
import { SvgLoader } from '@components/shared/SvgLoader'
import { StyledH2Title } from '@components/shared/styles'

import { IBreadcrumbsProps } from './models'
import { StyledBreadcrumbsWrapper } from './styles'

export const Breadcrumbs: React.FC<IBreadcrumbsProps> = ({
  title,
  backProps,
  whiteBackground,
  hasMaxWidth,
}) => {
  const renderBackAction = () => {
    return (
      <Link href={backProps?.url as string}>
        <a>
          <span>
            <SvgLoader {...LeftArrowIcon} />
            {backProps?.text}
          </span>
        </a>
      </Link>
    )
  }

  return (
    <StyledBreadcrumbsWrapper whiteBackground={whiteBackground}>
      <StyledBaseContainer hasMaxWidth={hasMaxWidth}>
        {backProps && renderBackAction()}
        <StyledH2Title>{title}</StyledH2Title>
      </StyledBaseContainer>
    </StyledBreadcrumbsWrapper>
  )
}
