import { gql } from '@apollo/client'

import { OPPORTUNITY_FRAGMENT } from '@graphql/journey/queries'

export const ME_FRAGMENT = gql`
  fragment me on UserType {
    id
    email
    auth0UserId
    firstName
    lastName
    groups
    flags {
      name
      active
    }
    profile {
      id
      timeZone
      firstName
      lastName
      phone
      avatar {
        url
      }
    }
    staffUser {
      id
    }
    contractor {
      id
    }
    customer {
      id
    }
    accounts {
      type
    }
    profilePicture {
      url
      filename
    }
  }
`

export const GET_AUTH_USER_AND_OPPORTUNITY = gql`
  query getAuthUserAndOpportunity {
    me {
      ...me
    }
    opportunity {
      ...opportunity
    }
  }
  ${ME_FRAGMENT}
  ${OPPORTUNITY_FRAGMENT}
`

export const GET_AUTH_USER = gql`
  query GetMe {
    me {
      ...me
    }
  }
  ${ME_FRAGMENT}
`
