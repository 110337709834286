import styled from 'styled-components'

import { StyledBaseContainer } from '@components/layout/PageLayout/styles'

export const StyledBreadcrumbsWrapper = styled(StyledBaseContainer)<{
  whiteBackground?: boolean
}>`
  background: ${({ theme, whiteBackground }) =>
    whiteBackground ? theme.colors.white : theme.colors.grayWii};
  padding-top: 20px;
  padding-bottom: 20px;

  > a {
    margin-bottom: 10px;
    color: ${({ theme }) => theme.colors.tealDollhouse};
    font-size: 10px;
    cursor: pointer;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
    > span {
      display: flex;
      > div {
        margin-right: 7px;
      }
    }
  }
`
