import { BREAKPOINTS, mediaQuery } from '@theme/responsive'
import styled from 'styled-components'

import { TextField } from '@components/forms'
import { SubmitButton } from '@components/forms/SubmitButton'

export const StyledCheckoutCard = styled.div`
  align-items: flex-start;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 0 15px;
  position: relative;
  ${({ theme }) =>
    mediaQuery(
      BREAKPOINTS.MEDIUM,
      `
      box-shadow: 0px 0px 8px rgba(149, 145, 142, 0.5);
      border: 1px solid ${theme.colors.tealPogs};
      padding: 30px;
    `
    )}
`

export const StyledCheckoutCardHeader = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.colors.tealRobots};
  display: flex;
  font-family: ${({ theme }) => theme.fonts.lato};
  font-size: 14px;
  font-weight: 600;
  width: 100%;
  margin-bottom: 50px;
  justify-content: end;
`

export const StyledCheckoutCardTitle = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.colors.tealDollhouse};
  display: flex;
  font-family: ${({ theme }) => theme.fonts.merriweather};
  font-size: 30px;
  font-weight: bold;
  line-height: 32px;
  :first-child {
    margin-bottom: 20px;
  }
`

export const StyledQtyInput = styled(TextField)`
  width: 60px;
  text-align: center;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    opacity: 1;
  }
`

export const StyledCheckoutForm = styled.form`
  border-top: 1px solid ${({ theme }) => theme.colors.grayNes};
  padding-top: 32px;
  width: 100%;
`
export const StyledSubmitButton = styled(SubmitButton)`
  margin-top: 32px;
  > div {
    margin-top: -8px;
  }
`

export const StyledFormActions = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const StyledChecboxesWrapper = styled.div`
  margin-top: 56px;
`

export const StyledLink = styled.a`
  color: inherit;
`
