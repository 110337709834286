import { BREAKPOINTS, mediaQuery } from '@theme/responsive'
import styled from 'styled-components'

export const StyledBaseContainer = styled.div<{
  hasMaxWidth?: boolean
  noGutter?: boolean
  noGutterMobile?: boolean
  maxWidth?: number
}>`
  padding-left: ${({ theme, noGutter, noGutterMobile }) =>
    noGutter || noGutterMobile ? 0 : theme.layout.gutterXMobile};
  padding-right: ${({ theme, noGutter, noGutterMobile }) =>
    noGutter || noGutterMobile ? 0 : theme.layout.gutterXMobile};
  ${({ hasMaxWidth, maxWidth }) =>
    hasMaxWidth &&
    `
    max-width: ${maxWidth ?? '1320'}px;
    margin: 0 auto;
    width: 100%;
  `}
  ${({ theme, noGutter }) =>
    mediaQuery(
      BREAKPOINTS.LARGE,
      `
      padding-left: ${noGutter ? 0 : theme.layout.gutterX};
      padding-right: ${noGutter ? 0 : theme.layout.gutterX};
    `
    )}
`

export const StyledFixedWrapper = styled.div`
  position: fixed;
  width: 100%;
  z-index: 2;
`

export const StyledTitle = styled.div`
  font-family: ${({ theme }) => theme.fonts.merriweather};
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  color: ${({ theme }) => theme.colors.tealDollhouse};
  margin: 40px 10px 20px;
`

export const StyledRoot = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

export const StyledBaseContainerWithMargin = styled(StyledBaseContainer)<{
  hasBreadcrumbs?: boolean
}>`
  padding-bottom: ${({ theme }) => theme.layout.gutterX};
  margin-top: ${({ hasBreadcrumbs }) => (hasBreadcrumbs ? '180px' : '120px')};
  ${({ hasBreadcrumbs }) =>
    mediaQuery(
      BREAKPOINTS.LARGE,
      `
      margin-top: ${hasBreadcrumbs ? '190px' : '130px'};
    `
    )}
`

export const StyledFieldGroup = styled.div`
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  margin-top: 20px;
  margin-bottom: 20px;

  > * {
    flex: 1;
    min-width: 320px;
  }
`
