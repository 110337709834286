import { createMessages } from '@lib/createMessage'

const prefix = 'components.forms.yeardropdownfield'

export const messages = createMessages({
  prefix,
  messages: {
    monthLabel: {
      defaultMessage: 'month',
      description: 'Month label',
    },
    yearLabel: {
      defaultMessage: 'year',
      description: 'Year label',
    },
    presentText: {
      defaultMessage: 'Present',
      description: 'Present value text',
    },
  },
})
