import { css, FlattenSimpleInterpolation } from 'styled-components'

export enum BREAKPOINTS {
  SMALL = 576,
  MEDIUM = 768,
  LARGE = 992,
  VERY_LARGE = 1200,
}

export const mediaQuery = (
  breakpoint: BREAKPOINTS,
  stylesToApply: string,
  mediaQueryType: 'max-width' | 'min-width' | 'max-height' | 'min-height' = 'min-width'
): FlattenSimpleInterpolation => {
  return css`
    @media screen and (${mediaQueryType}: ${breakpoint}px) {
      ${stylesToApply}
    }
  `
}
