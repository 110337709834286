import { gql } from '@apollo/client'

export const OPPORTUNITY_FRAGMENT = gql`
  fragment opportunity on OpportunityType {
    id
    completedAt
    surveyResponses {
      question
      questionId
      answer
      score
    }
  }
`

export const GET_OPPORTUNITY = gql`
  query GetOpportunities {
    opportunity {
      ...opportunity
    }
  }
  ${OPPORTUNITY_FRAGMENT}
`
