import CloseIcon from '@public/icons/close.svg'
import { BREAKPOINTS, mediaQuery } from '@theme/responsive'
import styled from 'styled-components'

import {
  StyledDropDownContent,
  StyledDropDownToggle,
  StyledDropMenu,
} from '@components/forms/DropDown/styles'

export const StyledUserMenuWrapper = styled.div`
  display: flex;
  align-items: center;
  .dropdown {
    ${({ theme }) =>
      mediaQuery(
        BREAKPOINTS.LARGE,
        `
      transition: margin .3s;
      margin-left: 65px;
      &.show {
        ${StyledDropDownToggle} {
          background: transparent;
          border: none;
          :before {
            position: absolute;
            content: '';
            background: ${theme.colors.white} url("${CloseIcon.src}") no-repeat center center;
            background-size: 24px 24px;
            width: 64px;
            height: 85px;
            right: -15px;
          }
        }
        ${StyledDropDownContent} {
          visibility: hidden;
          opacity: 0;
        }
        ${StyledDropMenu}.show {
          position: fixed !important;
          transform: none !important;
          inset: 91px 0 0 0 !important;
          border-radius: 0px;
          min-height: 100%;
          background: rgba(255, 255, 255, 0.95);
        }
      }
    `,
        'max-width'
      )}
  }

  ${StyledDropDownContent} {
    transition: visibility 0.3s, opacity 0.3s;
  }

  ${StyledDropDownToggle} {
    position: absolute;
    top: -27px;
    right: -5px;
    border-radius: 30px;
    transition: background 0.3s, border 0.3s;
    ${mediaQuery(BREAKPOINTS.LARGE, `position: static;`)}
    > div {
      padding: 5px;
      &:first-child {
        display: none;
        ${mediaQuery(BREAKPOINTS.LARGE, `display: block;`)}
      }
      > span {
        margin-right: 0;
        text-transform: uppercase;
      }
    }
    svg {
      margin-top: -3px;
    }
  }
  ${StyledDropMenu} {
    min-width: 250px;
    span {
      font-size: 20px;
      ${mediaQuery(BREAKPOINTS.LARGE, `font-size: inherit;`)}
    }
  }
`

export const StyledAdditionalLinkWrapper = styled.div`
  display: flex;
  align-items: center;
  ${mediaQuery(BREAKPOINTS.LARGE, `margin-right: 50px;`)}
`

export const StyledLink = styled.a<{ isActive?: boolean }>`
  display: flex;
  align-items: center;
  background: ${({ theme, isActive }) =>
    isActive ? theme.colors.tealPogs : theme.colors.tealDollhouse};
  padding: ${({ isActive }) => (isActive ? '20px 12px' : '12px')};
  color: ${({ theme, isActive }) => (isActive ? theme.colors.tealDollhouse : theme.colors.white)};
  border-radius: 8px 8px 0 0;
  font-weight: 600;
  font-size: 12px;
  text-decoration: none;
  margin: 0 0 ${({ isActive }) => (isActive ? '-26px' : '-46px')} 10px;
  cursor: pointer;
  transition: color 0.5s, background 0.3s, padding 0.3s, margin 0.3s;
  ${({ isActive }) =>
    mediaQuery(
      BREAKPOINTS.LARGE,
      `
        font-size: 20px;
        margin: 0 0 ${isActive ? '-16px' : '-34px'} 10px;
      `
    )}
  &:hover {
    color: ${({ theme }) => theme.colors.tealDollhouse};
    background: ${({ theme }) => theme.colors.tealPogs};
    padding: 20px 12px;
    margin-bottom: -26px;
    ${mediaQuery(
      BREAKPOINTS.LARGE,
      `
         margin-bottom: -14px;
      `
    )}
  }
  > div {
    width: 14px;
    margin-left: 10px;
  }
`
