import Logo from '@public/logo.svg'
import Link from 'next/link'
import React from 'react'

import { EVENTS, serializeData } from '@lib/tracking'

import { UserHeaderMenu } from '@components/layout/Header/UserHeaderMenu'
import { SvgLoader } from '@components/shared/SvgLoader'

import { StyledBaseContainer } from '../PageLayout/styles'
import { StyledHeader } from './styles'

export const Header: React.FC<{ url?: string; hasMaxWidth?: boolean }> = ({
  url = '/',
  hasMaxWidth = false,
}) => {
  return (
    <StyledHeader>
      <StyledBaseContainer hasMaxWidth={hasMaxWidth}>
        <Link href={url}>
          <a
            data-tracking-info={serializeData({
              id: EVENTS.NAVBAR_LOGO_CLICKED,
              origin_page_URL: window.location.href,
              destination_page_URL: url,
            })}
          >
            <SvgLoader {...Logo} />
          </a>
        </Link>
        <UserHeaderMenu />
      </StyledBaseContainer>
    </StyledHeader>
  )
}
