import { useCachedUser } from '@hooks/useCachedUser'
import HamburgerIcon from '@public/icons/hamburger.svg'
import LinkIcon from '@public/icons/link.svg'
import LogoutIcon from '@public/icons/logout.svg'
import PasswordIcon from '@public/icons/password.svg'
import ProfileIcon from '@public/icons/profile-card.svg'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React from 'react'
import { FormattedMessage, IntlShape, useIntl } from 'react-intl'

import { DropDown } from '@components/forms/DropDown'
import { DropDownItem } from '@components/forms/DropDown/models'
import {
  StyledUserMenuWrapper,
  StyledAdditionalLinkWrapper,
  StyledLink,
} from '@components/layout/Header/UserHeaderMenu/styles'
import { SvgLoader } from '@components/shared/SvgLoader'
import { StyledSvgLoader } from '@components/shared/SvgLoader/styles'
import { UserAvatar } from '@components/shared/UserAvatar'

import { messages } from './messages'

const items = (intl: IntlShape, hasPassword: boolean, profileId?: string) => {
  const headerItems = [] as DropDownItem[]

  if (profileId) {
    headerItems.push({
      text: intl.formatMessage(messages.profileLabel),
      value: `/profiles/${profileId}`,
      leftIcon: <StyledSvgLoader {...ProfileIcon} height={25} />,
    })
  }

  if (hasPassword) {
    headerItems.push({
      text: intl.formatMessage(messages.passwordLabel),
      value: '/password-reset',
      leftIcon: <StyledSvgLoader {...PasswordIcon} height={25} />,
    })
  }

  headerItems.push({
    text: intl.formatMessage(messages.logOutLabel),
    value: '/logout',
    leftIcon: <StyledSvgLoader {...LogoutIcon} height={25} />,
    hasDivider: headerItems.length > 0,
  })

  return headerItems
}

export const UserHeaderMenu: React.FC = () => {
  const intl = useIntl()
  const { isUserLogged, user, hasPlatformAccess, hasCreatorAccount } = useCachedUser()
  const { push, pathname } = useRouter()

  const handleOnChange = (url: string) => {
    push(url)
  }

  return (
    <StyledUserMenuWrapper>
      {isUserLogged && user && (
        <>
          <StyledAdditionalLinkWrapper>
            {hasPlatformAccess && (
              <StyledLink target="_blank" href={`https://${process.env.APP_HOST}`}>
                <FormattedMessage {...messages.myProjectsLabel} />
                <SvgLoader {...LinkIcon} />
              </StyledLink>
            )}
            {!hasPlatformAccess && (
              <Link href="/experts/book">
                <StyledLink isActive={pathname === '/experts/book'}>
                  <FormattedMessage {...messages.bookAnExpertLabel} />
                </StyledLink>
              </Link>
            )}
            {hasCreatorAccount && (
              <Link href="/resources">
                <StyledLink isActive={pathname === '/resources'}>
                  <FormattedMessage {...messages.myResourcesLabel} />
                </StyledLink>
              </Link>
            )}
          </StyledAdditionalLinkWrapper>
          <DropDown
            defaultValue="100" // just to avoid ant selected, because we don t want any
            onChange={handleOnChange}
            items={items(intl, !!user.auth0UserId, user.profile?.id)}
            rightIcon={<StyledSvgLoader {...HamburgerIcon} />}
            hasDropDownArrow={false}
            dropDownBodyContent={
              <UserAvatar
                firstName={user.profile?.firstName ?? user.firstName}
                lastName={user.profile?.lastName ?? user.lastName}
                avatar={user.profile?.avatar}
              />
            }
          />
        </>
      )}
    </StyledUserMenuWrapper>
  )
}
