import mapValues from 'lodash.mapvalues'
import { defineMessages, MessageDescriptor } from 'react-intl'

interface IMessageDescriptorWithoutId {
  description: string
  defaultMessage: string
}

type MessagesWithoutId<Names extends keyof Record<string, string> = string> = {
  [key in Names]: IMessageDescriptorWithoutId
}

export declare type Messages<Names extends keyof Record<string, string> = string> = {
  [key in Names]: MessageDescriptor
}

export function createMessages<Names extends keyof Record<string, string> = string>({
  prefix,
  messages,
}: {
  prefix: string
  messages: MessagesWithoutId<Names>
}): Messages<Names> {
  const prefixedMessages = mapValues(
    messages,
    (value: IMessageDescriptorWithoutId, key: string) =>
      ({
        description: value.description,
        defaultMessage: value.defaultMessage,
        id: `${prefix}.${key}`,
      } as MessageDescriptor)
  ) as Messages<Names>
  return defineMessages(prefixedMessages)
}
