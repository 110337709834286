import { createMessages } from '@lib/createMessage'

const prefix = 'components.textareaField'

export const messages = createMessages({
  prefix,
  messages: {
    characterCountLabel: {
      defaultMessage: 'Character Count: {currentLength} of {maxLength}',
      description: 'Character count label',
    },
  },
})
