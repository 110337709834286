import { BREAKPOINTS, mediaQuery } from '@theme/responsive'
import { Badge, Col } from 'react-bootstrap'
import styled from 'styled-components'

import { StyledSubmitButton } from '@components/Package/CheckoutForm/styles'
import { StyledBaseContainer } from '@components/layout/PageLayout/styles'
import { StyledUserAvatarWrapper } from '@components/shared/UserAvatar/styles'

export const StyledRightSideBoxWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const StyledLeftSideBoxWrapper = styled.div`
  background: ${({ theme }) => theme.colors.grayWii};
  display: flex;
  align-items: center;
  justify-content: center;
  > div {
    hr {
      opacity: 1;
      height: 5px;
      border-radius: 20px;
      background: ${({ theme }) => theme.colors.white};
      &:first-of-type {
        margin: 0 0 35px;
      }
      &:last-of-type {
        margin: 35px 0 0;
      }
    }
    h1 {
      font-family: ${({ theme }) => theme.fonts.merriweather};
      font-size: 35px;
      font-weight: 400;
      color: ${({ theme }) => theme.colors.tealDollhouse};
      ${mediaQuery(
        BREAKPOINTS.VERY_LARGE,
        `
          font-size: 42px;
          line-height: 53px;
        `
      )}
    }
    p {
      font-size: 15px;
      font-weight: 400;
      color: ${({ theme }) => theme.colors.tealDollhouse};
      margin: 30px 0 0 0;
    }
  }
`

// reusable card pieces
export const StyledCardListWrapper = styled.div`
  box-shadow: 0px 0px 8px rgba(149, 145, 142, 0.5);
  border-radius: 20px;
  overflow: hidden;
  transition: all 0.2s ease;
  height: 100%;
  display: flex;
  flex-direction: column;
`

export const StyledCardListImage = styled.div`
  position: relative;
  > span {
    height: 120px;
    width: 100%;
    img {
      height: inherit;
      width: 100%;
      object-fit: cover;
    }
  }
`

export const StyledCardListBand = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0 115px 0 25px;
  background: rgba(247, 246, 245, 0.6);
  display: flex;
  backdrop-filter: blur(6px);
  font-weight: 700;
  font-size: 24px;
  font-style: italic;
  font-family: ${({ theme }) => theme.fonts.relation};
  justify-content: right;
  color: ${({ theme }) => theme.colors.tealDollhouse};
  > div {
    position: absolute;
    top: -20px;
    right: 25px;
    width: 75px;
    height: 75px;
    border-radius: 50%;
    background: ${({ theme }) => theme.colors.grayWii};
    box-shadow: 0 0 15px rgba(149, 145, 142, 0.5);
    border: 3px solid ${({ theme }) => theme.colors.grayNes};
    display: flex;
    align-items: center;
    justify-content: center;
    > div {
      width: 50px;
    }
  }
`

export const StyledCardListBody = styled.div`
  margin: 10px;
  font-family: ${({ theme }) => theme.fonts.merriweather};
  font-size: 14px;
  color: ${({ theme }) => theme.colors.tealDollhouse};
  flex: 2;

  > a {
    color: inherit;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`

export const StyledCardListFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background: ${({ theme }) => theme.colors.grayWii};
  border-top: 1px solid ${({ theme }) => theme.colors.grayDarker};
`
// end reusable card piecaes

export const StyledDivider = styled.div`
  width: 100%;
  height: 1px;
  border: 0.5px solid ${({ theme }) => theme.colors.grayNes};
  margin: 10px 0;
`

export const StyledH2Title = styled.h2`
  font-family: ${({ theme }) => theme.fonts.merriweather};
  font-size: 24px;
  font-weight: 700;
  margin: 0;
  color: ${({ theme }) => theme.colors.tealDollhouse};
  ${mediaQuery(
    BREAKPOINTS.LARGE,
    `
      font-size: 36px;
    `
  )}
`

interface IStyledButtonProps {
  // Default: gray bg, green text and border
  primary?: boolean // Green bg
  primaryLight?: boolean // Gray bg, green text
  secondary?: boolean // Purple bg
  secondaryLight?: boolean // Gray bg, purple text
}

export const StyledButton = styled.button<IStyledButtonProps>`
  ${({ theme, primary, primaryLight, secondary, secondaryLight }) => {
    const buttonBorder = () => {
      if (primary || secondary || primaryLight || secondaryLight) return 'none'

      return `2px solid ${theme.colors.tealRobots}`
    }

    const buttonBackground = () => {
      if (primary) return theme.colors.tealRobots
      if (secondary) return theme.colors.purpleBopit
      if (primaryLight) return 'rgba(222, 219, 217, 0.4)'

      return theme.colors.grayWii
    }

    const buttonDisabledBackground = () => {
      if (primary) return 'rgba(0, 153, 141, 0.5)'
      if (secondary) return 'rgba(122, 96, 150, 0.5)'

      return 'rgba(247, 246, 245, 0.5)'
    }

    const buttonTextColor = () => {
      if (primary || secondary) return theme.colors.white
      if (secondaryLight) return theme.colors.purpleBopit
      if (primaryLight) return theme.colors.tealFurby

      return theme.colors.tealFurby
    }

    return `
      height: 48px;
      width: fit-content;
      display: flex;
      align-items: center;
      justify-content: center;
      border: ${buttonBorder()};
      border-radius: 10px;
      background: ${buttonBackground()};
      color: ${buttonTextColor()};
      font-family: ${theme.fonts.lato};
      font-size: 18px;
      font-weight: ${secondary || secondaryLight || primaryLight ? '700' : '500'};
      letter-spacing: 0.5px;
      line-height: 48px;
      text-transform: lowercase;
      padding: 0px 45px;
      :disabled {
        opacity: 0.8;
        background: ${buttonDisabledBackground()};
      }

      flex: 1 0 100%;
  `
  }}
  ${mediaQuery(
    BREAKPOINTS.MEDIUM,
    `
      flex: unset;
    `
  )}
`

export const StyledButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;

  ${mediaQuery(
    BREAKPOINTS.MEDIUM,
    `
      margin-top: 120px;
    `
  )}
`

export const StyledIconButton = styled.button`
  background-color: transparent;
  border: none;
  appearance: none;
  -webkit-appearance: none;
`

export const StyledListColumn = styled(Col)`
  margin-bottom: 30px;
`

export const StyledResourcesButton = styled(StyledSubmitButton)<{ fluid?: boolean }>`
  display: flex;
  border: 1px solid ${({ theme }) => theme.colors.purpleMegatron};
  color: ${({ theme }) => theme.colors.purpleBopit};
  padding: 10px 20px;
  width: ${({ fluid }) => (fluid ? '100%' : 'auto')};
  justify-content: ${({ fluid }) => (fluid ? 'center' : 'initial')};
  margin: 0;
  > div {
    width: 20px;
    margin-top: -2px;
  }
`

export const StyledResourcesButtonSimple = styled(StyledSubmitButton)`
  border: none;
  color: ${({ theme }) => theme.colors.purpleBopit};
  padding: 0;
  background: transparent;
  margin-top: 0;
`

export const StyledDownloadButton = styled(StyledResourcesButton)`
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.greenTMNT};
  color: ${({ theme }) => theme.colors.greenGumby};
`

export const StyledProfileName = styled.div`
  font-family: ${({ theme }) => theme.fonts.merriweather};
  font-weight: 700;
  font-size: 36px;
  line-height: 46px;
  text-align: center;
  color: ${({ theme }) => theme.colors.tealDollhouse};
`

export const StyledProfileSubElement = styled.div`
  font-family: ${({ theme }) => theme.fonts.lato};
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: ${({ theme }) => theme.colors.tealFurby};
  > a {
    color: inherit;
    text-decoration: none;
    &:hover {
      color: inherit;
      text-decoration: underline;
    }
  }
`

export const StyledProfileNameAndTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1 0 100%;
  padding-bottom: 20px;
  min-height: 89px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grayNes};

  ${mediaQuery(
    BREAKPOINTS.LARGE,
    `
    flex: unset;
    padding-bottom: 0;
    border-bottom: none;
        `
  )}
`

export const StyledProfileAvatar = styled.div`
  display: flex;
  align-items: flex-end;
  min-width: 100px;
  height: 100px;
  padding-bottom: 15px;

  ${mediaQuery(
    BREAKPOINTS.LARGE,
    `
    padding-bottom: 10px;
          `
  )}

  > ${StyledUserAvatarWrapper} {
    width: 100px;
    height: 100px;
    font-size: 40px;
    box-shadow: 0px 1px 4px rgba(38, 5, 91, 0.2);
    margin-bottom: -30px;

    ${mediaQuery(
      BREAKPOINTS.LARGE,
      `
      margin-bottom: -25px;
              `
    )}
  }
`

export const StyledProfileHeader = styled.div`
  position: relative;
  margin-top: 90px;

  ${StyledBaseContainer} {
    height: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 15px;

    > *:nth-child(1) {
      order: 1;
      min-width: 100px;
    }

    > *:nth-child(2) {
      order: 3;
    }

    > *:nth-child(3) {
      order: 2;
    }

    ${mediaQuery(
      BREAKPOINTS.LARGE,
      `
        flex-wrap: nowrap;

        > *:nth-child(2) {
          order: 2;
        }

        > *:nth-child(3) {
          order: 3;
        }
      `
    )}
  }
`

export const StyledProfileHeaderBackground = styled.div<{ $image?: string }>`
  position: absolute;
  width: 100%;
  height: 100px;
  top: 0;
  left: 0;
  z-index: -1;
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    filter: blur(30px);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-color: ${({ theme }) => theme.colors.tealLiteBrite};
    background-image: ${({ $image }) => ($image ? `url(${$image})` : 'none')};
  }

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.colors.tealLiteBrite};
    opacity: 0.8;
  }
`

export const StyledPill = styled.div<{
  selected?: boolean
  disabled?: boolean
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
  overflow: hidden;
  border-radius: 110px;
  padding: 5px;
  background: ${({ theme }) => theme.colors.grayWii};

  --border-color: ${({ selected, theme }) =>
    selected ? theme.colors.tealGlove : theme.colors.grayNes};
  --border-width: ${({ selected }) => (selected ? '6px' : '3px')};
  --border-style: 0 0 0 var(--border-width) var(--border-color);
  --shadow-style: ${({ selected }) =>
    selected ? '0px 5px 20px  rgba(0, 30, 40, 0.3)' : '0px 0px 0px #000'};
  box-shadow: var(--border-style), var(--shadow-style);

  ${(props) =>
    !props.disabled &&
    `
      cursor: pointer;
      &:hover {
        --border-width: 6px;
        div {
          ${mediaQuery(
            BREAKPOINTS.LARGE,
            `
              --shadow-style: 0px 5px 20px  rgba(0, 30, 40, 0.3);
            `
          )}
        }
      }
    `}

  div {
    transition: filter 0.2s, box-shadow 0.2s;
    width: 75px;
    height: 75px;
    border-radius: 50%;
    overflow: hidden;
    img,
    span {
      max-width: 100%;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
    ${mediaQuery(
      BREAKPOINTS.LARGE,
      `
        min-width: 150px;
        min-height: 236px;
        border-radius: 110px;
        box-shadow: var(--border-style), var(--shadow-style);
      `
    )}
  }
  > span {
    color: ${({ theme }) => theme.colors.tealDollhouse};
    font-weight: 400;
    font-size: 18px;
    text-align: center;
    flex: 1;
    text-align: left;
    margin-left: 20px;
    ${mediaQuery(
      BREAKPOINTS.LARGE,
      `
        flex: auto;
        text-align: center;
        margin-left: 0;
        margin-top: 20px;
      `
    )}
  }
  ${mediaQuery(
    BREAKPOINTS.LARGE,
    `
      border-radius: 0;
      flex-direction: column;
      background: transparent;
      margin-bottom: 50px;
      padding: 0;
      border: none;
      overflow: visible;
      box-shadow: none;
    `
  )}
`

export const StyledTagsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px 10px;
  margin-top: 20px;
`

export const TagBadge = styled(Badge)<{ suggestion?: boolean; flat?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 18px;
  background: ${({ theme, suggestion }) =>
    suggestion ? theme.colors.tealPogs : theme.colors.white};
  border-radius: 100px;
  border: ${({ theme, flat }) => (flat ? `1px solid ${theme.colors.grayNes}` : 'none')};
  box-shadow: ${({ suggestion, flat }) =>
    suggestion || flat ? '0px' : '0px 0px 10px rgba(38, 5, 91, 0.12)'};
  flex: none;
  order: 0;
  flex-grow: 0;
  font-family: ${({ theme }) => theme.fonts.lato};
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.tealDollhouse};
  gap: 12px;

  > button {
    margin-right: -8px;
  }
`

export const StyledLink = styled.a`
  margin-left: 20px;
  color: ${({ theme }) => theme.colors.tealFurby};
  text-decoration: none;

  &:hover {
    color: ${({ theme }) => theme.colors.tealDollhouse};
  }
`

export const StyledFieldGroup = styled.div.attrs((props: { readonly: boolean }) => props)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${({ readonly }) => (readonly ? '' : 'space-between')};
  border: 1px solid;
  border-color: ${({ theme, readonly }) => (readonly ? 'transparent' : theme.colors.grayNes)};
  background: ${({ theme, readonly }) => (readonly ? 'transparent' : theme.colors.grayWii)};
  border-radius: 10px;
  width: 100%;
  height: 56px;
  padding: 0px 18px;
`
