import React from 'react'
import { FormattedMessage } from 'react-intl'

import { messages } from './messages'
import { ITextAreaFieldProps } from './models'
import { StyledCharacterCounter, StyledErrorLabel, StyledLabel, StyledTextArea } from './styles'

export const TextAreaField: React.FC<ITextAreaFieldProps> = ({
  label,
  value,
  icon,
  error,
  required,
  maxLength,
  ...rest
}) => {
  return (
    <StyledLabel icon={icon} hasError={!!error} required={required}>
      <span>{label}</span>
      <StyledTextArea {...rest} value={value} required={required} maxLength={maxLength} />
      {error && <StyledErrorLabel>{error}</StyledErrorLabel>}
      {maxLength && (
        <StyledCharacterCounter>
          <FormattedMessage
            {...messages.characterCountLabel}
            values={{ currentLength: value?.length || 0, maxLength }}
          />
        </StyledCharacterCounter>
      )}
    </StyledLabel>
  )
}
