import styled from 'styled-components'

import { StyledBaseContainer } from '@components/layout/PageLayout/styles'

export const StyledHeader = styled.div`
  background: ${({ theme }) => theme.colors.tealFurby};
  color: ${({ theme }) => theme.colors.white};
  padding-top: 14px;
  padding-bottom: 14px;
  z-index: 3;
  border-bottom: 3px solid ${({ theme }) => theme.colors.tealDollhouse};

  ${StyledBaseContainer} {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`

export const StyledAlternativeHeader = styled.div`
  width: 100%;
  height: 80px;
  background: ${({ theme }) => theme.colors.grayWii};
  padding: 14px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.tealDollhouse};
`
