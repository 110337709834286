import styled from 'styled-components'

import { StyledBaseContainer } from '@components/layout/PageLayout/styles'

export const StyledFooterWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`

export const StyledFooter = styled.div`
  background-color: ${({ theme }) => theme.colors.grayWii};
  height: 45px;
  padding-top: 10px;
  padding-bottom: 10px;

  ${StyledBaseContainer} {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`

export const StyledFooterSection = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.grayNickelArcade};

  a {
    color: inherit;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  },
`
