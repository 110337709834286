import Head from 'next/head'
import React from 'react'

import { Footer } from '@components/layout/Footer'
import { Header } from '@components/layout/Header'
import { AlternativeHeader } from '@components/layout/Header/AlternativeHeader'
import { StyledFixedWrapper, StyledRoot } from '@components/layout/PageLayout/styles'
import { Breadcrumbs } from '@components/shared/Breadcrumbs'
import { Toast } from '@components/shared/Toast'

import { IPageLayoutProps } from './models'

export const PageLayout: React.FC<IPageLayoutProps> = ({
  title,
  hasBreadcrumbs = true,
  hasFooter = true,
  hasAlternateHeader,
  hasMaxWidth,
  breadcrumbsProps,
  additionalHeadElements,
  additionalBodyScripts,
  children,
}) => {
  const HeaderComponent = hasAlternateHeader ? AlternativeHeader : Header

  return (
    <>
      <Head>
        <title>{title}</title>
        {additionalHeadElements && additionalHeadElements}
      </Head>
      <StyledRoot>
        <StyledFixedWrapper>
          <HeaderComponent url="https://gembah.com" hasMaxWidth={hasMaxWidth} />
          {hasBreadcrumbs && <Breadcrumbs {...breadcrumbsProps} hasMaxWidth={hasMaxWidth} />}
          <Toast />
        </StyledFixedWrapper>
        {children}
        {hasFooter && <Footer />}
      </StyledRoot>
      {additionalBodyScripts && additionalBodyScripts}
    </>
  )
}
