import { AccountType, IUser } from '@models/user'

export const PUBLIC_URLS = ['/vendor/[vendorSlug]']

export const mapUserAccounts = (user?: IUser): AccountType[] => {
  return (user?.accounts ?? []).map((account) => account.type)
}

export const isUserExpert = (user?: IUser): boolean => {
  const accounts = mapUserAccounts(user)
  return accounts.includes(AccountType.Expert)
}

export const isUserCreator = (user?: IUser): boolean => {
  const accounts = mapUserAccounts(user)
  return accounts.includes(AccountType.Creator)
}
