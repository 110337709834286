import EyeClosedIcon from '@public/icons/eye-closed.svg'
import EyeIcon from '@public/icons/eye.svg'
import PasswordIcon from '@public/icons/password.svg'
import React, { useState } from 'react'

import { TextField } from './TextField'
import { ITextFieldProps } from './models'

export const PasswordRegExp = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d).+$')

export const TextPasswordField: React.FC<ITextFieldProps> = ({ ...props }) => {
  const [passwordInputType, setPasswordInputType] = useState<string>('password')

  return (
    <TextField
      type={passwordInputType}
      icon={PasswordIcon}
      button={{
        icon: passwordInputType === 'text' ? EyeClosedIcon : EyeIcon,
        onClick: () => {
          setPasswordInputType(passwordInputType === 'text' ? 'password' : 'text')
        },
      }}
      {...props}
    />
  )
}
