import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'

import { createRange } from '@lib/utils'

import { DropDown } from '@components/forms/DropDown'
import { DropDownItem } from '@components/forms/DropDown/models'

import { StyledLabel } from '../TextField/styles'
import { messages } from './messages'
import { StyledDateSelectorField } from './styles'

interface IDateSelectorFieldProps {
  id?: string
  label?: string
  error?: string
  onChange?: (date: string | null) => void
  value?: string
  required?: boolean
  yearOnly?: boolean
  disabledBefore?: string
}

interface IExtractGranularDateReturn {
  year?: number
  month?: number
  day?: number
}

export const extractGranularDate = (date: string): IExtractGranularDateReturn => {
  const momentDate = moment(date)

  return {
    day: momentDate.day(),
    month: momentDate.month(),
    year: momentDate.year(),
  }
}

export const PresentValue = '-'

export const DateSelectorField: React.FC<IDateSelectorFieldProps> = ({
  id,
  value,
  onChange,
  label,
  error,
  yearOnly = false,
  required,
  disabledBefore,
}) => {
  const intl = useIntl()

  const isPresentValue = value === PresentValue
  const currentDate = value && !isPresentValue ? moment(value, 'YYYY-MM-DD') : null

  const [selectedMonth, setSelectedMonth] = useState<string>(
    isPresentValue && !yearOnly ? '-' : currentDate?.month().toString() ?? ''
  )
  const [selectedYear, setSelectedYear] = useState<string>(
    isPresentValue && yearOnly ? '-' : currentDate?.year().toString() ?? ''
  )
  const [disableYear, setDisableYear] = useState<boolean>(false)

  const currentYear = new Date().getUTCFullYear()
  const processedBeforeDate = disabledBefore ? extractGranularDate(disabledBefore) : {}

  const beforeYear = processedBeforeDate?.year
  const beforeMonth = processedBeforeDate?.month
  const disabledBeforeYear = beforeYear ?? currentYear

  const isSameAsDisabledYear = selectedYear && parseInt(selectedYear) === disabledBeforeYear
  const monthsRange =
    beforeMonth && isSameAsDisabledYear ? createRange(beforeMonth, 12) : createRange(0, 12)
  const yearsRange = beforeYear ? createRange(0, currentYear - beforeYear + 1) : createRange(0, 100)

  const months = monthsRange.map((i) => {
    return {
      value: i.toString(),
      text: intl.formatDate(new Date().setMonth(i), { month: 'long' }),
    }
  }) as unknown as DropDownItem[]

  function unshiftPresentOptionIn(items: DropDownItem[]) {
    items.unshift({
      value: PresentValue,
      text: intl.formatMessage(messages.presentText),
    })
    return items
  }

  const years = yearsRange.map((i) => {
    const year = (currentYear - i).toString()
    return {
      value: year,
      text: year,
    }
  }) as unknown as DropDownItem[]

  useEffect(() => {
    if (disabledBefore && selectedMonth && selectedYear) {
      const parsedSelectedYear = parseInt(selectedYear)
      const parsedSelectedMonth = parseInt(selectedMonth)

      if (beforeYear && parsedSelectedYear < beforeYear) setSelectedYear(beforeYear.toString())
      if (beforeMonth && isSameAsDisabledYear && parsedSelectedMonth < beforeMonth)
        setSelectedMonth(beforeMonth.toString())
    }
  }, [
    isSameAsDisabledYear,
    beforeYear,
    beforeMonth,
    disabledBefore,
    selectedYear,
    selectedMonth,
    setSelectedYear,
    setSelectedMonth,
  ])

  useEffect(() => {
    if (selectedMonth === PresentValue || selectedYear === PresentValue) {
      if (!yearOnly) {
        setDisableYear(true)
        if (selectedYear) {
          setSelectedYear('')
        }
      }
      onChange?.(PresentValue)
      return
    }

    if (disableYear) {
      setDisableYear(false)
    }

    if (selectedYear && (yearOnly || selectedMonth !== undefined)) {
      const date = new Date()
      date.setDate(1)
      date.setUTCFullYear(parseInt(selectedYear as string))
      if (!yearOnly && selectedMonth !== undefined) {
        date.setUTCMonth(parseInt(selectedMonth as string))
      } else {
        date.setUTCMonth(0)
      }

      onChange?.(moment(date).format('YYYY-MM-DD'))
    }
  }, [selectedMonth, selectedYear])

  return (
    <StyledLabel id={id} required={required}>
      <span>{label}</span>
      <StyledDateSelectorField splitRow={!yearOnly}>
        {!yearOnly && (
          <DropDown
            items={disabledBefore ? unshiftPresentOptionIn(months) : months}
            onChange={(m) => setSelectedMonth(m)}
            allowEmpty
            defaultValue={selectedMonth}
            placeholder={intl.formatMessage(messages.monthLabel)}
            disabled={typeof disabledBefore !== 'undefined' && typeof beforeMonth === 'undefined'}
          />
        )}
        <DropDown
          items={disabledBefore && yearOnly ? unshiftPresentOptionIn(years) : years}
          onChange={(y) => setSelectedYear(y)}
          allowEmpty
          defaultValue={selectedYear}
          placeholder={intl.formatMessage(messages.yearLabel)}
          disabled={
            (typeof disabledBefore !== 'undefined' && typeof beforeYear === 'undefined') ||
            disableYear
          }
        />
      </StyledDateSelectorField>
      {error && <span>{error}</span>}
    </StyledLabel>
  )
}
