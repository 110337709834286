import Link from 'next/link'
import React from 'react'

import { EVENTS, serializeData } from '@lib/tracking'

import { StyledBaseContainer } from '../PageLayout/styles'
import { StyledFooterWrapper, StyledFooter, StyledFooterSection } from './styles'

export const Footer: React.FC = () => {
  return (
    <StyledFooterWrapper>
      <StyledFooter>
        <StyledBaseContainer hasMaxWidth>
          <StyledFooterSection>
            © {new Date().getFullYear()} Gembah. All Rights Reserved.
          </StyledFooterSection>
          <StyledFooterSection>
            <Link href="https://gembah.com/privacy-policy">
              <a
                target="_blank"
                data-tracking-info={serializeData({
                  id: EVENTS.FOOTER_PRIVACY_POLICY_CLICKED,
                  route: 'https://gembah.com/privacy-policy',
                })}
              >
                Privacy Policy
              </a>
            </Link>
          </StyledFooterSection>
        </StyledBaseContainer>
      </StyledFooter>
    </StyledFooterWrapper>
  )
}
