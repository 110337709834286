import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'

import { IUserAvatarProps } from '@components/shared/UserAvatar/models'
import { StyledUserAvatarWrapper } from '@components/shared/UserAvatar/styles'

export const UserAvatar: React.FC<IUserAvatarProps> = ({
  firstName,
  lastName,
  letters,
  avatar,
}) => {
  return (
    <StyledUserAvatarWrapper>
      {avatar ? (
        <LazyLoadImage src={avatar.url} />
      ) : (
        <div>{letters ? letters : `${firstName?.[0] ?? ''} ${lastName?.[0] ?? ''}`}</div>
      )}
    </StyledUserAvatarWrapper>
  )
}
