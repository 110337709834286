import { createMessages } from '@lib/createMessage'

const prefix = 'components.userHeaderMenu'

export const messages = createMessages({
  prefix,
  messages: {
    profileLabel: {
      defaultMessage: 'My Profile',
      description: 'Profile label',
    },
    loginLabel: {
      defaultMessage: 'Login',
      description: 'Login label',
    },
    logOutLabel: {
      defaultMessage: 'Log Out',
      description: 'Log Out label',
    },
    passwordLabel: {
      defaultMessage: 'My Password',
      description: 'Password label',
    },
    myResourcesLabel: {
      defaultMessage: 'my resources',
      description: 'My resources label',
    },
    myProjectsLabel: {
      defaultMessage: 'my projects',
      description: 'My projects',
    },
    bookAnExpertLabel: {
      defaultMessage: 'book an expert',
      description: 'Book an expert link label',
    },
  },
})
