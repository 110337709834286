export enum ToastTypes {
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
  INFO = 'info',
  NEUTRAL = 'neutral',
}

export interface IToast {
  id?: string
  message: JSX.Element | string
  headerText?: string
  type: ToastTypes
  noManualClose?: boolean
  noAutoClose?: boolean
  autoCloseTime?: number
  onClose?: () => void
}

export interface IUseToastReturn {
  toasts: IToast[]
  addToast: (toast: IToast) => void
  deleteToast: (id: string, onClose?: () => void) => void
  clearToasts: () => void
  getLatestAppError: () => JSX.Element | string
}
