import styled from 'styled-components'

export const StyledLabel = styled.label.attrs(
  (props: { icon: { src: string }; hasError?: boolean; required?: boolean }) => props
)`
  color: ${({ theme, hasError }) => (hasError ? theme.colors.redJEM : theme.colors.tealRobots)};
  font-size: 16px;
  font-weight: bold;
  line-height: 20px;
  position: relative;
  width: 100%;
  > input {
    border: 1px solid
      ${({ theme, hasError }) => (hasError ? theme.colors.redJEM : theme.colors.grayNes)};
    background: ${({ theme, hasError }) =>
      hasError ? theme.colors.redSurprise : theme.colors.grayWii};
    color: ${({ theme, hasError }) => (hasError ? theme.colors.redJEM : 'inherit')};
  }

  ${(props) =>
    props.icon?.src &&
    `
  ::before {
    background-image: url('${props.icon.src}');
    background-position-x: 1px;
    background-position-y: 1px;
    background-repeat: no-repeat;
    background-size: contain;
    content: ' ';
    height: 20px;
    left: 18px;
    position: absolute;
    top: 48px;
    width: 20px;
  }
  `}

  ${({ required, theme }) =>
    required &&
    `
    > span {
      ::after {
        content: '*';
        color: ${theme.colors.redJEM};
        margin-left: 5px;
      }
    }
  `}
`

export const StyledErrorLabel = styled.span`
  margin-top: 10px;
  padding: 5px;
  background: ${({ theme }) => theme.colors.redSurprise};
  box-shadow: 0px 0px 8px rgba(149, 145, 142, 0.3);
  border-radius: 3px;
  font-family: ${({ theme }) => theme.fonts.lato};
  font-size: 12px;
`

export const StyledTextArea = styled.textarea`
  align-items: center;
  background: ${({ theme }) => theme.colors.grayWii};
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.colors.grayNes};
  color: ${({ theme }) => theme.colors.tealDollhouse};
  display: flex;
  flex-direction: row;
  font-size: 18px;
  font-weight: 500;
  height: 200px;
  margin: 10px 0 0;
  padding: 12px 20px;
  width: 100%;
  margin-bottom: 10px;

  ::placeholder {
    color: ${({ theme }) => theme.colors.grayGameboy};
    font-family: ${({ theme }) => theme.fonts.merriweather};
    font-size: 18px;
    font-style: italic;
    font-weight: 300;
    text-transform: lowercase;
  }

  &:focus,
  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.tealPowerglove};
    outline: none;
  }
`
export const StyledCharacterCounter = styled.div`
  padding: 2px 12px;
  background: ${({ theme }) => theme.colors.purpleKoosh};
  border-radius: 3px;
  color: ${({ theme }) => theme.colors.tealDollhouse};
  font-family: ${({ theme }) => theme.fonts.lato};
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
`
