import { useQuery } from '@apollo/client'

import { isUserCreator, isUserExpert } from '@lib/userUtils'

import { GET_AUTH_USER_AND_OPPORTUNITY } from '@graphql/user/queries'

import { IUser } from '@models/user'

import { OpportunityResponse } from '@components/journey/models'

export interface IUseCachedUserReturn {
  isUserLogged: boolean
  isGembahExpertAdmin: boolean
  hasExpertAccount: boolean
  hasCreatorAccount: boolean
  hasPlatformAccess: boolean
  opportunity?: OpportunityResponse['opportunity']
  user?: IUser
}

export const useCachedUser = (): IUseCachedUserReturn => {
  const { data } = useQuery<{ me: IUser } & OpportunityResponse>(GET_AUTH_USER_AND_OPPORTUNITY, {
    fetchPolicy: 'cache-only',
  })

  return {
    isUserLogged: !!data,
    isGembahExpertAdmin:
      data?.me?.flags?.find((f) => f.name === 'isGembahExpertAdmin')?.active ?? false,
    hasPlatformAccess: !!(data?.me?.contractor || data?.me?.staffUser || data?.me?.customer),
    hasExpertAccount: isUserExpert(data?.me),
    hasCreatorAccount: isUserCreator(data?.me),
    opportunity: data?.opportunity,
    user: data?.me,
  }
}
