import styled from 'styled-components'

import { SvgLoader } from '.'

export const StyledSvgLoader = styled(SvgLoader)<{
  width?: string | number
  height?: string | number
}>`
  svg {
    ${({ width, height }) => `
      width: ${width}px;
      height: ${height}px;`}
  }
`
