import React from 'react'

import { SvgLoader } from '@components/shared/SvgLoader'

import { ISubmitButtonProps } from './models'
import { StyledSubmitButtonContainer } from './styles'

export const SubmitButton: React.FC<ISubmitButtonProps> = ({
  iconRight,
  iconLeft,
  children,
  ...rest
}) => {
  return (
    <StyledSubmitButtonContainer {...rest}>
      {iconLeft && <SvgLoader {...iconLeft} />}
      <span>{children}</span>
      {iconRight && <SvgLoader {...iconRight} />}
    </StyledSubmitButtonContainer>
  )
}
