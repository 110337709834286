import { Toast, ToastContainer } from 'react-bootstrap'
import styled from 'styled-components'

import { ToastTypes } from '@models/toast'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const extractToastStylesByType = (type: ToastTypes, theme: any): string => {
  switch (type) {
    case ToastTypes.ERROR:
      return `
        background: ${theme.colors.redSurprise};
        color: ${theme.colors.redPowerRangers};
      `
    case ToastTypes.SUCCESS:
      return `
        background: ${theme.colors.greenGloWorm};
        color: ${theme.colors.greenCabbagePatch};
      `
    case ToastTypes.INFO:
      return `
        background: ${theme.colors.purpleBeanieBaby};
        color: ${theme.colors.purpleTrolls};
      `
    case ToastTypes.WARNING:
      return `
        background: ${theme.colors.goldenRod0};
        color: ${theme.colors.goldenRod1000};
      `
    default:
      return ''
  }
}

export const StyledToast = styled(Toast)<{ type: ToastTypes }>`
  width: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 0;
  background: ${({ theme }) => theme.colors.white};
  font-size: 18px;
  font-style: italic;
  ${({ type, theme }) => extractToastStylesByType(type, theme)}
`

export const StyledToastHeader = styled(Toast.Header)`
  color: inherit;
  padding: 0;
  height: 0;
  .btn-close {
    background-image: none;
    color: inherit;
    position: absolute;
    top: 5px;
    margin: 0;
    right: 13px;
    width: auto;
    font-style: italic;
    text-decoration: underline;
    &:after {
      content: 'dismiss';
    }
  }
  span {
    font-family: ${({ theme }) => theme.fonts.merriweather};
    color: ${({ theme }) => theme.colors.tealDollhouse};
  }
`

export const StyledToastBody = styled(Toast.Body)`
  max-width: 90%;
`

export const StyledToastContainer = styled(ToastContainer)`
  width: 100%;
  position: static !important;
`
