import useToast from '@hooks/useToast'
import React from 'react'

import {
  StyledToast,
  StyledToastBody,
  StyledToastContainer,
  StyledToastHeader,
} from '@components/shared/Toast/styles'

export const Toast: React.FC = () => {
  const { toasts, deleteToast } = useToast()

  return (
    <StyledToastContainer position="top-end">
      {toasts.map(
        ({ id, message, onClose, type, noManualClose, autoCloseTime, headerText, noAutoClose }) => (
          <StyledToast
            key={id}
            onClose={() => deleteToast(id!, onClose)}
            delay={autoCloseTime}
            autohide={!noAutoClose}
            type={type}
          >
            <StyledToastHeader closeButton={!noManualClose}>
              <span className="me-auto">{headerText}</span>
            </StyledToastHeader>
            <StyledToastBody>{message}</StyledToastBody>
          </StyledToast>
        )
      )}
    </StyledToastContainer>
  )
}
