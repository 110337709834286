import React from 'react'
import { Form } from 'react-bootstrap'
import ReactDOMServer from 'react-dom/server'
import { useFormContext, Controller } from 'react-hook-form'

import { ICheckboxGroupProps } from './models'
import { StyledCheck, StyledCheckLabel, StyledCheckInput } from './styles'

export const CheckboxGroup: React.FC<ICheckboxGroupProps> = ({
  items,
  name,
  variant = 'sm',
  onClick,
  noForm = false,
  control,
  rules,
}) => {
  const checkboxControl = control ?? useFormContext()?.control

  const CheckboxList = () => (
    <>
      {items.map(({ label, key }, i) => (
        <Controller
          key={i}
          control={checkboxControl}
          name={key}
          defaultValue={false}
          rules={rules}
          render={({ field }) => (
            <StyledCheck type="checkbox" id={`checkbox-group-${name}-${i}`} label={label}>
              <StyledCheckInput
                onChange={field.onChange}
                checked={field.value}
                type="checkbox"
                bsPrefix="custom-checkbox"
                variant={variant}
                onClick={(e: React.ChangeEvent<HTMLInputElement>) => {
                  // handles cases where the label is an array of React elements
                  const stringifiedLabel =
                    typeof label === 'string' ? label : ReactDOMServer.renderToString(label)
                  onClick?.(e, {
                    label: stringifiedLabel,
                    key,
                  })
                }}
              />
              <StyledCheckLabel variant={variant}>{label}</StyledCheckLabel>
            </StyledCheck>
          )}
        />
      ))}
    </>
  )

  return noForm ? (
    <div>
      <CheckboxList />
    </div>
  ) : (
    <Form name={name}>
      <CheckboxList />
    </Form>
  )
}
