import React from 'react'
import { ReactSVG } from 'react-svg'

import { ISvgLoader } from './models'

export const SvgLoader: React.FC<ISvgLoader> = ({
  src,
  className,
  wrapper = 'div',
  width,
  height,
  title,
}) => {
  return (
    <ReactSVG
      className={className}
      src={src}
      wrapper={wrapper}
      width={width}
      height={height}
      title={title}
      alt={title}
    />
  )
}
