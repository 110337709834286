import { BREAKPOINTS, mediaQuery } from '@theme/responsive'
import styled from 'styled-components'

export const StyledUserAvatarWrapper = styled.div`
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.tealDollhouse};
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.white};
  overflow: hidden;
  img {
    max-width: 100%;
  }
  > div {
    white-space: nowrap;
    text-transform: uppercase;
  }
  ${mediaQuery(
    BREAKPOINTS.LARGE,
    `
    width: 32px;
    height: 32px;
  `
  )}
`
