import Logo from '@public/logo-color.svg'
import Link from 'next/link'
import React from 'react'

import { EVENTS, serializeData } from '@lib/tracking'

import { StyledAlternativeHeader } from '@components/layout/Header/styles'
import { StyledBaseContainer } from '@components/layout/PageLayout/styles'
import { SvgLoader } from '@components/shared/SvgLoader'

export const AlternativeHeader: React.FC<{ url?: string; hasMaxWidth?: boolean }> = ({
  url = '/',
  hasMaxWidth = false,
}) => {
  return (
    <StyledAlternativeHeader>
      <StyledBaseContainer hasMaxWidth={hasMaxWidth}>
        <Link href="https://gembah.com">
          <a
            data-tracking-info={serializeData({
              id: EVENTS.NAVBAR_LOGO_CLICKED,
              origin_page_URL: window.location.href,
              destination_page_URL: url,
            })}
          >
            <SvgLoader {...Logo} />
          </a>
        </Link>
      </StyledBaseContainer>
    </StyledAlternativeHeader>
  )
}
