import { makeEllipsisText } from '@theme/utils'
import { Dropdown } from 'react-bootstrap'
import styled, { css } from 'styled-components'

export const SharedStyledDropMenu = css`
  overflow: hidden;
  border-radius: 15px;
  border: 1px solid ${({ theme }) => theme.colors.tealMonopoly};
  box-shadow: 0px 10px 30px rgba(149, 145, 142, 0.5);
  font-family: ${({ theme }) => theme.fonts.merriweather};
  max-height: 500px;
  overflow: auto;
  width: 100%;
  a {
    font-size: 15px;
    font-weight: normal;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.colors.tealDollhouse};
    > span {
      ${makeEllipsisText()}
    }

    > div + span,
    > span + div {
      margin-left: 12px;
    }
    &:hover,
    &.active {
      color: ${({ theme }) => theme.colors.tealDollhouse};
      background: ${({ theme }) => theme.colors.tealLiteBrite};
    }
  }
`

export const StyledDropdownFilterInput = styled.input`
  border: none;
  background: inherit;
  width: 100%;
  :focus-visible {
    outline: 1px solid transparent;
  }
  ::placeholder {
    color: ${({ theme }) => theme.colors.grayGameboy};
    font-style: italic;
  }
`

export const StyledDropDownToggle = styled.div<{
  isOpen: boolean
  noContent: boolean
  disabled?: boolean
}>`
  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  background: ${({ theme, disabled }) => (disabled ? theme.colors.white : theme.colors.grayWii)};
  display: flex;
  align-items: center;
  border-radius: 10px;
  color: ${({ theme, noContent, disabled }) =>
    disabled
      ? theme.colors.grayNes
      : noContent
      ? theme.colors.grayGameboy
      : theme.colors.tealDollhouse};
  border: 1px solid
    ${({ theme, isOpen }) => (isOpen ? theme.colors.tealMonopoly : theme.colors.grayNes)};

  box-shadow: ${({ isOpen }) => (isOpen ? '0px 0px 8px 0px rgba(149, 145, 142, 0.3)' : 'none')};

  &:hover {
    border-color: ${({ theme, isOpen }) =>
      isOpen ? theme.colors.tealMonopoly : theme.colors.tealPowerglove};
  }
  > span {
    padding: 12px 10px;
    background: red;
    align-self: stretch;
    border-radius: 10px 0 0 10px;
    background: ${({ theme }) => theme.colors.grayDarker};
  }
  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    > span {
      width: 100%;
      margin-right: 12px;
      font-family: ${({ theme }) => theme.fonts.lato};
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      ${({ noContent, theme }) =>
        noContent &&
        `
        text-transform: lowercase;
        font-family: ${theme.fonts.merriweather};
        font-style: italic;
        font-weight: 300;
        font-size: 18px;
        line-height: 23px;
      `}
    }
  }
`

export const StyledDropDownContent = styled.div`
  width: 100%;
`

export const StyledDropMenu = styled(Dropdown.Menu)`
  ${SharedStyledDropMenu}
`
