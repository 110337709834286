import { Button } from 'react-bootstrap'
import InputMask from 'react-input-mask-format'
import styled, { css } from 'styled-components'

import { StyledSvgLoader } from '@components/shared/SvgLoader/styles'

export const StyledLabel = styled.label.attrs(
  (props: { icon: { src: string }; hasError?: boolean; required?: boolean }) => props
)`
  color: ${({ theme, hasError }) =>
    hasError ? theme.colors.redPowerRangers : theme.colors.tealRobots};
  font-size: 16px;
  font-weight: bold;
  line-height: 20px;
  position: relative;
  width: 100%;

  ${({ required, theme }) =>
    required &&
    `
    > span {
      ::after {
        content: '*';
        color: ${theme.colors.redStrawberryShortcake};
        margin-left: 5px;
      }
    }
  `}
`

export const StyledErrorLabel = styled.span`
  margin-top: 10px;
  padding: 5px;
  background: ${({ theme }) => theme.colors.redSurprise};
  box-shadow: 0px 0px 8px rgba(149, 145, 142, 0.3);
  border-radius: 3px;
  font-family: ${({ theme }) => theme.fonts.lato};
  font-size: 12px;
`

const inputStyle = css`
  background: inherit;
  border: none !important;
  outline: 0px;
  width: 100%;
  margin-left: 10px;
  padding-right: 8px;
  color: inherit;

  ::placeholder {
    color: ${({ theme }) => theme.colors.grayGameboy};
    font-family: ${({ theme }) => theme.fonts.merriweather};
    font-size: 18px;
    font-style: italic;
    font-weight: 300;
    text-transform: lowercase;
  }
`

export const StyledInput = styled.input`
  ${inputStyle}
`

export const StyledInputMask = styled(InputMask)`
  ${inputStyle}
`

export const StyledInputIcon = styled(StyledSvgLoader)`
  margin-left: 10px;
`

export const StyledInputWrapper = styled.div.attrs((props: { hasError?: boolean }) => props)`
  border: 1px solid
    ${({ theme, hasError }) =>
      hasError ? theme.colors.redStrawberryShortcake : theme.colors.grayNes};
  background: ${({ theme, hasError }) =>
    hasError ? theme.colors.redSurprise : theme.colors.grayWii};
  padding-left: 8px;
  align-items: center;
  border-radius: 10px;
  color: ${({ theme, hasError }) =>
    hasError ? theme.colors.redPowerRangers : theme.colors.tealDollhouse};
  display: flex;
  flex-direction: row;
  font-size: 18px;
  font-weight: 500;
  height: 56px;
  margin: 10px 0 0;
  width: 100%;
  margin-bottom: 10px;

  &:focus-within {
    border: 1px solid
      ${({ theme, hasError }) =>
        hasError ? theme.colors.redStrawberryShortcake : theme.colors.tealPowerglove};
    outline: none;
  }

  &:hover {
    border: 1px solid
      ${({ theme, hasError }) =>
        hasError ? theme.colors.redStrawberryShortcake : theme.colors.tealPowerglove};
  }
`

export const StyledInputButton = styled(Button)`
  background: ${({ theme }) => theme.colors.grayNes};
  border-top-left-radius: 0px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 10px;
  height: 100%;
`
