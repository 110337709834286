import React from 'react'

import { StyledSvgLoader } from '@components/shared/SvgLoader/styles'

import { ITextFieldProps } from './models'
import {
  StyledLabel,
  StyledInputWrapper,
  StyledInput,
  StyledInputMask,
  StyledErrorLabel,
  StyledInputButton,
  StyledInputIcon,
} from './styles'

export const TextField: React.FC<ITextFieldProps> = ({
  label,
  icon,
  button,
  error,
  required,
  mask,
  ...rest
}) => {
  return (
    <StyledLabel icon={icon} hasError={!!error} required={required}>
      {label && <span>{label}</span>}
      <StyledInputWrapper hasError={!!error}>
        {icon && <StyledInputIcon {...icon} width={20} height={20} />}
        {mask ? (
          <StyledInputMask mask={mask} {...rest} required={required} />
        ) : (
          <StyledInput {...rest} required={required} />
        )}
        {button && (
          <StyledInputButton onClick={button.onClick} variant="link">
            <StyledSvgLoader {...button.icon} width={20} height={20} />
          </StyledInputButton>
        )}
      </StyledInputWrapper>
      {error && <StyledErrorLabel>{error}</StyledErrorLabel>}
    </StyledLabel>
  )
}
