import CheckmarkIcon from '@public/icons/checkmark.svg'
import { Form } from 'react-bootstrap'
import styled from 'styled-components'

export const StyledCheck = styled(Form.Check)`
  align-items: center;
  display: flex;
  margin-bottom: 8px;
`

export const StyledCheckLabel = styled(Form.Check.Label).attrs(
  (props: { variant: string }) => props
)`
  color: ${({ theme }) => theme.colors.tealDollhouse};
  cursor: pointer;
  font-family: ${({ theme }) => theme.fonts.lato};
  font-size: ${(props) => (props.variant === 'lg' ? '16px' : '14px')};
  line-height: ${(props) => (props.variant === 'lg' ? '20px' : '14px')};
  margin-left: ${(props) => (props.variant === 'lg' ? '8px' : '6px')};
  user-select: none;
  max-width: ${(props) => (props.variant === 'lg' ? 'calc(100% - 28px)' : 'calc(100% - 16px)')};
`

export const StyledCheckInput = styled(Form.Check.Input).attrs(
  (props: { variant: string }) => props
)`
  cursor: pointer;
  height: 0px;
  width: ${(props) => (props.variant === 'lg' ? '20px' : '14px')};
  position: relative;
  -moz-appearance: initial;
  appearance: none;
  -webkit-appearance: none;

  :checked {
    ::before {
      background: ${({ theme }) => theme.colors.tealDollhouse};
      background-image: url('${CheckmarkIcon.src}');
      background-position-x: 1px;
      background-position-y: 1px;
      background-repeat: no-repeat;
      background-size: ${(props) => (props.variant === 'lg' ? '16px 16px' : '10px 10px')};
      content: ' ';
      height: ${(props) => (props.variant === 'lg' ? '20px' : '14px')};
      width: ${(props) => (props.variant === 'lg' ? '20px' : '14px')};
    }
  }

  ::before {
    border-radius: 3px;
    border: 1px solid ${({ theme }) => theme.colors.grayNes};
    background: ${({ theme }) => theme.colors.white};
    content: ' ';
    height: ${(props) => (props.variant === 'lg' ? '20px' : '15px')};
    margin: 0;
    position: absolute;
    transform: translateY(-50%);
    width: ${(props) => (props.variant === 'lg' ? '20px' : '15px')};
  }
`
