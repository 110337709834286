import CadIcon from '@public/icons/mimetypes/cad.svg'
import CsvIcon from '@public/icons/mimetypes/csv.svg'
import ExcelIcon from '@public/icons/mimetypes/excel.svg'
import GenericIcon from '@public/icons/mimetypes/generic.svg'
import GoogleDriveIcon from '@public/icons/mimetypes/google-drive.svg'
import ImageIcon from '@public/icons/mimetypes/image.svg'
import PdfIcon from '@public/icons/mimetypes/pdf.svg'
import TxtIcon from '@public/icons/mimetypes/txt.svg'
import WordIcon from '@public/icons/mimetypes/word.svg'
import { NextRouter } from 'next/dist/shared/lib/router/router'

export const sortElements = <E>(elements: E[], by: keyof E): E[] =>
  elements.sort((a, b) => (a[by] as unknown as string).localeCompare(b[by] as unknown as string))

export const createRange = (start: number, stop: number, step = 1): number[] =>
  Array(Math.ceil((stop - start) / step))
    .fill(start)
    .map((x, y) => x + y * step)

export function formatNumberWithSeparator(
  numberToFormat: number,
  separator = ',',
  limit = 3
): string {
  if (numberToFormat === null) {
    return ''
  }
  try {
    // Safari doesn't like this regex
    // https://caniuse.com/js-regexp-lookbehind
    return numberToFormat
      .toString()
      .replace(new RegExp(`\\B(?<!\\.\\d*)(?=(\\d{${limit}})+(?!\\d))`, 'g'), separator)
  } catch (_) {
    return numberToFormat
      .toString()
      .replace(new RegExp(`(\\d{1,2}?)((\\d{${limit}})+)$`), `$1${separator}$2`)
      .replace(new RegExp(`(\\d{${limit}})(?=\\d)`, 'g'), `$1${separator}`)
  }
}

export const setShallowRouter = (router: NextRouter): void => {
  router.replace(
    {
      pathname: router.pathname,
      query: router.query,
    },
    undefined,
    { shallow: true }
  )
}

export const generateRandomString = (len: number): string =>
  Math.random()
    .toString(36)
    .replace(/[^a-z]+/g, '')
    .slice(0, len)

export const MIME_TYPES_FOR_ICONS = {
  CAD_DRAWING: 'image/x-dwg',
  CAD_INTERCHANGE: 'image/x-dxf',
  CAD_WEB_FORMAT: 'drawing/x-dwf',
  CSV: 'text/csv',
  DOCX: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  JPEG: 'image/jpeg',
  PDF: 'application/pdf',
  PNG: 'image/png',
  TXT: 'text/plain',
  XLS: 'application/vnd.ms-excel',
  XLSX: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  GOOGLE_DRIVE: 'google-drive',
}

export const getFileIcon = (mimetype: string): { src: string } => {
  switch (mimetype) {
    case MIME_TYPES_FOR_ICONS.CAD_DRAWING:
    case MIME_TYPES_FOR_ICONS.CAD_INTERCHANGE:
    case MIME_TYPES_FOR_ICONS.CAD_WEB_FORMAT:
      return CadIcon

    case MIME_TYPES_FOR_ICONS.CSV:
      return CsvIcon

    case MIME_TYPES_FOR_ICONS.DOCX:
      return WordIcon

    case MIME_TYPES_FOR_ICONS.JPEG:
    case MIME_TYPES_FOR_ICONS.PNG:
      return ImageIcon

    case MIME_TYPES_FOR_ICONS.PDF:
      return PdfIcon

    case MIME_TYPES_FOR_ICONS.TXT:
      return TxtIcon

    case MIME_TYPES_FOR_ICONS.GOOGLE_DRIVE:
      return GoogleDriveIcon

    case MIME_TYPES_FOR_ICONS.XLS:
    case MIME_TYPES_FOR_ICONS.XLSX:
      return ExcelIcon

    default:
      return GenericIcon
  }
}
