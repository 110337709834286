import { IUserProfile } from '@models/profiles'

export enum AccountType {
  Creator = 'CREATOR',
  Expert = 'EXPERT',
}

export interface IUserFlag {
  name: string
  active: boolean
}

export interface IUserAccount {
  type: AccountType
}

export interface IUserType {
  id: string
}

export interface IUser {
  email: string
  firstName: string
  groups: string[]
  id: string
  lastName: string
  profilePicture?: {
    url: string
    filename: string
  }
  profile: IUserProfile
  flags: IUserFlag[]
  accounts: IUserAccount[]
  contractor: IUserType
  customer: IUserType
  staffUser: IUserType
  auth0UserId?: string // If empty, user hasn't defined his password
}
